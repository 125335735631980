import './App.css';
// import { Route, BrowserRouter } from 'react-router-dom'
import WebApp from './components/WebApp/WebApp';
import WebSite from './components/WebSite/WebSite';

import React from 'react';
import { Router } from 'react-router-dom';

import { history } from './_helpers';
import { authenticationService } from './_services';

// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null
        };
    }

    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    }

    render() {
        const { currentUser } = this.state;
        return (
          <div className="App">
            <Router history={history}>
                <div>
                    <WebApp />
                    <WebSite />
                </div>
            </Router>
    </div>
        );
    }
}

export default App;
