import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserProject.module.css';
import Project from '../Project/Project';
import axios from 'axios';
import queryString from 'query-string';
import { history } from '../../_helpers';
import { authenticationService } from './../../_services';

class UserProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        data: undefined
    };
    this.queryParams = queryString.parse(this.props.location.search)
  }

  componentDidMount() {
    axios.get('http://15.236.91.33:443/meshData/project?name='+this.queryParams.name, {
        headers: {
          'Authorization': 'Bearer ' + authenticationService.currentUserValue.auth_token,
          'Authorization_Source': authenticationService.currentUserValue.auth_token_source
        }})
    .then(res => {
        this.setState({
            data: res.data.data
        }, window.webAppSideNavToggle);
    })
    .catch(err => console.log(err))
  }

  render() {
    return(
      <div className={styles.UserProject}>
          <Project key={this.state.data} data={this.state.data}/>
      </div>
    );
  }
}

UserProject.propTypes = {};

UserProject.defaultProps = {};

export default UserProject;
