import { BehaviorSubject } from 'rxjs';

import { handleResponse } from './../_helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const API_BASE_URL = "http://15.236.91.33:443"

export const authenticationService = {
    login,
    logout,
    register,
    activate,
    subscribeUser,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(email, password, rememberMe) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(API_BASE_URL + `/auth/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (rememberMe) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
            }

            return subscribeUser(user);
        });
}

function subscribeUser(user) {
    currentUserSubject.next(user);
    return user;
}

function register(newUser) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newUser)
    };

    return fetch(API_BASE_URL + `/auth/register`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function activate(code) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: code })
    };

    return fetch(API_BASE_URL + `/auth/activate`, requestOptions)
        .then(res=>res.json())
        .then(user => {
            return user;
        })
        .catch(e => {
            console.log(e);
            return e
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}
