import React from 'react';
import PropTypes from 'prop-types';
import styles from './WebApp.css';
import LoginPage from './../LoginPage/LoginPage';
import RegisterPage from './../RegisterPage/RegisterPage';
import { PrivateRoute } from './../../_components';
import { Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import ForgetPasswordPage from '../ForgetPasswordPage/ForgetPasswordPage';
import AccountActivationPage from '../AccountActivationPage/AccountActivationPage';
import SampleProject from '../SampleProject/SampleProject';
import UploadModelPage from '../UploadModelPage/UploadModelPage';
import UserProject from '../UserProject/UserProject';
import ResetPassword from '../ResetPassword/ResetPassword';

class WebApp extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
        <div className="">
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/dashboard/sample" component={SampleProject} />
            <PrivateRoute exact path="/dashboard/project" component={UserProject} />
            <PrivateRoute exact path="/dashboard/project/new" component={UploadModelPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/forgot-password" component={ForgetPasswordPage} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/activate-account" component={AccountActivationPage} />
        </div>
    );
  }
}

WebApp.propTypes = {};

WebApp.defaultProps = {};

export default WebApp;
