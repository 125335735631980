import React from 'react';
import PropTypes from 'prop-types';
import styles from './RegisterPage.module.css';
import { authenticationService } from './../../_services';

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
    
        // redirect to home if already logged in
        if (authenticationService.currentUserValue) { 
            this.props.history.push('/dashboard');
        }
        this.state = {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          repeatPassword: '',
          isSending: false
        };
        this.handleRegisterClicked = this.handleRegisterClicked.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.handleRepeatPasswordChanged = this.handleRepeatPasswordChanged.bind(this);
        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
    }

    handleEmailChanged(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordChanged(event) {
        this.setState({password: event.target.value});
    }

    handleRepeatPasswordChanged(event) {
        this.setState({repeatPassword: event.target.value});
    }

    handleFirstNameChanged(event) {
        this.setState({firstName: event.target.value});
    }

    handleLastNameChanged(event) {
        this.setState({lastName: event.target.value});
    }

    handleRegisterClicked() {
        this.setState({isSending: true});
        authenticationService.register
            ({ email: this.state.email,
               password: this.state.password,
               repeatPassword: this.state.repeatPassword,
               firstName: this.state.firstName,
               lastName: this.state.lastName })
            .then(
                user => {
                    this.setState({
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        repeatPassword: '',
                        isSending: false
                    });
                    this.props.history.push('/activate-account');
                },
                error => {
                this.setState({
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    repeatPassword: '',
                    isSending: false
                });
                }
            );
    }

    render() {
        return (
            <div className={styles.RegisterPage}>
                <div className="container">
                <div className="card o-hidden border-0 shadow-lg my-10">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
                            <div className="col-lg-7">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                                    </div>
                                    <form className="user">
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <input type="text" className="form-control form-control-user" id="exampleFirstName"
                                                    placeholder="First Name"
                                                    onChange={this.handleFirstNameChanged} />
                                            </div>
                                            <div className="col-sm-6">
                                                <input type="text" className="form-control form-control-user" id="exampleLastName"
                                                    placeholder="Last Name"
                                                    onChange={this.handleLastNameChanged} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control form-control-user" id="exampleInputEmail"
                                                placeholder="Email Address"
                                                onChange={this.handleEmailChanged} />
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                <input type="password" className="form-control form-control-user"
                                                    id="exampleInputPassword" placeholder="Password"
                                                    onChange={this.handlePasswordChanged} />
                                            </div>
                                            <div className="col-sm-6">
                                                <input type="password" className="form-control form-control-user"
                                                    id="exampleRepeatPassword" placeholder="Repeat Password"
                                                    onChange={this.handleRepeatPasswordChanged} />
                                            </div>
                                        </div>
                                        { this.state.isSending
                                          ? <div className="row justify-content-center">
                                                <div className="spinner-grow" style={{width: "2.4rem", height: "2.4rem"}} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                           : <a onClick={this.handleRegisterClicked} className="btn btn-primary btn-user btn-block mt-5" style={{marginBottom: '1.45rem'}}>
                                                 Register Account
                                             </a>
                                        }
                                    </form>
                                    <hr />
                                    <div className="text-center">
                                        <a className="small" href="/forgot-password">Forgot Password?</a>
                                    </div>
                                    <div className="text-center">
                                        <a className="small" href="/login">Already have an account? Login!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    }
}

RegisterPage.propTypes = {};

RegisterPage.defaultProps = {};

export default RegisterPage;
