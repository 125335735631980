import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './KnowledgePage.module.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import ListPosts from '../ListPosts/ListPosts';
import queryString from 'query-string';
import axios from 'axios';

export class KnowledgePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoriesIds: [],
            isLoaded: false
        };
    }

    componentDidMount() {
        axios.get('https://bio-cfd.com/wp-json/wp/v2/categories')
            .then(res => {
                this.setState({
                    categoriesIds: res.data.filter(category => {
                        return category.name == "knowledge";
                    })
                    .map(category => {
                        return category.id;
                    }),
                    isLoaded: true,
                });
            })
            .catch(err => console.log(err))
    }

    render() {
        return(
            <div className={styles.KnowledgePage}>
                <NavBar darkText style={{backgroundColor: "#fff"}} />
                <header className="page-header page-header-dark bg-img-cover overlay overlay-60 mb-3" style={{backgroundImage: "url(https://source.unsplash.com/PTRzqc_h1r4/1600x900)"}}>
                    <div className="page-header-content">
                        <div className="container text-center">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <h1 className="page-header-title mb-3">Knowledge</h1>
                                    <p className="page-header-text mb-0">Browse articles, keep up to date, and learn more on our blog!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="svg-border-rounded text-light">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                    </div>
                </header>
                {this.state.isLoaded
                ? <ListPosts sourcePath="/knowledge" baseUrl={'https://bio-cfd.com/wp-json/wp/v2/posts?_embed&categories=' + this.state.categoriesIds.join(',')} queryParams={queryString.parse(this.props.location.search)}/>
                : <div className="row justify-content-center mt-5 mb-10">
                    <div className="spinner-grow" style={{width: "3rem", height: "3rem"}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                }
                <Footer/>
            </div>
        );
    }
};

KnowledgePage.propTypes = {};

KnowledgePage.defaultProps = {};

export default KnowledgePage;
