import React from 'react';
import PropTypes from 'prop-types';
import styles from './ResetPassword.module.css';
import queryString from 'query-string';
import axios from 'axios';

import { authenticationService } from './../../_services';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) { 
        this.props.history.push('/dashboard');
    }

    this.state = {
      newPass: '',
      confirmNewPass: '',
      error: undefined,
      sending: false,
      success: false
    };

    const queryParams = queryString.parse(this.props.location.search);
    this.code = queryParams.code;
  
    this.handleNewPassChanged = this.handleNewPassChanged.bind(this);
    this.handleConfirmNewPassChanged = this.handleConfirmNewPassChanged.bind(this);
    this.handleResetClicked = this.handleResetClicked.bind(this);
  }

  handleNewPassChanged(e) {
    this.setState({
      newPass: e.target.value,
      confirmNewPass: this.state.confirmNewPass
    });
  }

  handleConfirmNewPassChanged(e) {
    this.setState({
      newPass: this.state.newPass,
      confirmNewPass: e.target.value
    });
  }

  handleResetClicked() {
    if (this.state.newPass != this.state.confirmNewPass) {
      this.setState({error: 'The confirm new password field must match the new password field'});
    }
    else if (this.state.newPass.length == 0 || this.state.confirmNewPass.length == 0) {
      this.setState({error: 'The new password can not be empty'});
    }
    else {
      this.setState({sending: true});
      axios.post('http://15.236.91.33:443/auth/resetPassword', {password: this.state.newPass, code: this.code})
      .then(response => {
          if (response.status != 'fail') {
              this.setState({
                sending: false,
                success: true,
                error: undefined
              });
          }
          else {
              this.setState({
                error: 'Password can not be updated',
                sending: false
              });
          }
          console.log('Response', response.data)
      })
      .catch(e => {
          this.setState({
            error: 'Password can not be updated',
            sending: false
          });
          console.log('Error: ', e.response)
      })
    }
  }

  render() {
    return (
<div className={styles.ResetPassword}>
          <div className={styles.LoginPage}>
        <div className="container">

          <div className="row justify-content-center">

              <div className="col-xl-12 col-lg-12 col-md-9">

                  <div className="card o-hidden border-0 shadow-lg my-20">
                      <div className="card-body p-0">
                          <div className="row">
                              <div className="col-lg-6 d-none d-lg-block bg-account-activation-image"></div>
                              <div className="col-lg-6">
                                   <div className="pt-5 px-5 pb-4">
                                      <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">Reset password</h1>
                                        <p className="text-center">Please enter the new password</p>
                                      </div>
                                      {this.state.sending ?
                                      <div className="row justify-content-center">
                                          <div className="spinner-grow" style={{width: "2.4rem", height: "2.4rem"}} role="status">
                                              <span className="sr-only">Loading...</span>
                                          </div>
                                      </div>
                                    :
                                    <div>
                                      {this.state.error && <p className="text-center text-red">{this.state.error}</p>}
                                      {this.state.success && <p className="text-center text-green">Password is updated. please login</p>}
                                      {! this.state.success && <form class="user">
                                                <div class="form-group">
                                                    <input onChange={this.handleNewPassChanged} type="password" class="form-control form-control-user"
                                                        id="newPassword" value={this.state.newPass}
                                                        placeholder="Enter a new password..." />
                                                    <input onChange={this.handleConfirmNewPassChanged} type="password" class="form-control form-control-user mt-3"
                                                        id="confirmNewPassword" value={this.state.confirmNewPass}
                                                        placeholder="Confirm the new password..." />
                                                </div>
                                                <a onClick={this.handleResetClicked} class="btn btn-primary btn-user btn-block">
                                                    Reset Password
                                                </a>
                                            </form>}
                                            </div>
                                    }
                                      <hr />
                                      <div className="text-center">
                                      <a className="small" href="/login">Back to login page</a>
                                  </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
  </div>
    );
  }
}

ResetPassword.propTypes = {};

ResetPassword.defaultProps = {};

export default ResetPassword;
