import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContactUsPage.module.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

const ContactUsPage = () => (
  <div className={styles.ContactUsPage}>
    <NavBar trans fixed darkText style={{backgroundColor: "#212832"}}/>
    <header class="page-header page-header-dark bg-primary pb-10 mb-5">
        <div class="page-header-content pt-5">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <h1 class="page-header-title mb-3">40+ years combined experience in CFD research and development</h1>
                        <p class="page-header-text">Our engineering team is lead by three Ph.D. holders with broad and extensive experience in Computational Fluid Dynamics. With proven track record in developing OpenFOAM technology for research and industrial applications, our team strives to deliver the highest quality standards in biomedical CFD applications.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="svg-border-rounded text-light">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
        </div>
    </header>
    <section class="bg-light py-10">
        <div class="container">
            <div class="row mb-5">
                <div class="col-lg-4 mb-10 mt-4">
                    <a class="card card-link border-top border-top-lg border-primary lift text-center o-visible h-100" href="#!"
                        ><div class="card-body">
                            <div class="icon-stack icon-stack-big bg-primary-soft text-primary mb-5 mt-n5 z-1 shadow">
                              <img className="avatar avatar-big avatar-img svg" style={{maxWidth: 'none'}} src="https://bio-cfd.com/wp-content/uploads/2020/07/19984084_10159350350525019_3960395146599732948_o.jpg" />
                            </div>
                            <h5 className="mt-4">Khalid Saqr</h5>
                            <p class="card-text">Founder</p>
                        </div>
                        <div class="card-footer">
                            <div class="text-primary font-weight-bold d-inline-flex align-items-center row">
                              <a className="col" style={{fontSize: "30px"}} target="_blank" href="https://khalidsaqr.bio/"><i className="fas fa-link"></i></a>
                              <a className="col" style={{fontSize: "30px"}} target="_blank" href="https://www.linkedin.com/in/khalidsaqr/"><i className="fab fa-linkedin"></i></a>
                              <a className="col" style={{fontSize: "30px"}} target="_blank" href="https://www.researchgate.net/profile/Khalid_Saqr"><i className="fab fa-researchgate"></i></a>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mb-10 mt-4">
                    <a class="card card-link border-top border-top-lg border-secondary lift text-center o-visible h-100" href="#!"
                        ><div class="card-body">
                            <div class="icon-stack icon-stack-xl bg-secondary-soft text-secondary mb-4 mt-n5 z-1 shadow">
                              <img className="avatar avatar-big avatar-img svg" style={{maxWidth: 'none'}} src="http://bio-cfd.com/wp-content/uploads/2020/07/WhatsApp-Image-2020-07-06-at-09.37.29.jpeg" />
                            </div>
                            <h5 className="mt-4">Mahmoud Aboukhedr</h5>
                            <p class="card-text">Co-founder</p>
                        </div>
                        <div class="card-footer">
                          <div class="text-primary font-weight-bold d-inline-flex align-items-center row">
                              <a className="col" style={{fontSize: "30px"}} target="_blank" href="https://www.linkedin.com/in/mahmoud-aboukhedr-ph-d-8370514b/"><i className="fab fa-linkedin"></i></a>
                              <a className="col" style={{fontSize: "30px"}} target="_blank" href="https://www.researchgate.net/profile/Mahmoud-Aboukhedr"><i className="fab fa-researchgate"></i></a>
                          </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4 mb-10 mt-4">
                    <a class="card card-link border-top border-top-lg border-teal lift text-center o-visible h-100" href="#!"
                        ><div class="card-body">
                            <div class="icon-stack icon-stack-xl bg-teal-soft text-teal mb-4 mt-n5 z-1 shadow">
                              <img className="avatar avatar-big avatar-img svg" style={{maxWidth: 'none'}} src="http://bio-cfd.com/wp-content/uploads/2020/07/hassan.jpg" />
                            </div>
                            <h5 className="mt-4">Hassan Kassem</h5>
                            <p class="card-text">Co-founder</p>
                        </div>
                        <div class="card-footer">
                          <div class="text-primary font-weight-bold d-inline-flex align-items-center row">
                              <a className="col" style={{fontSize: "30px"}} target="_blank" href="http://hassankassem.me/"><i className="fas fa-link"></i></a>
                              <a className="col" style={{fontSize: "30px"}} target="_blank" href="https://www.linkedin.com/in/hassankassem10/"><i className="fab fa-linkedin"></i></a>
                              <a className="col" style={{fontSize: "30px"}} target="_blank" href="https://www.researchgate.net/profile/Hassan_Kassem10"><i className="fab fa-researchgate"></i></a>
                          </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="row justify-content-center text-center">
                <div class="col-lg-5">
                    <h5>General Support</h5>
                    <p class="font-weight-light mb-0">For any other support questions, please send us an email at <a href="#!">support@bio-cfd.com</a></p>
                </div>
            </div>
        </div>
        <div class="svg-border-rounded text-dark">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
        </div>
    </section>
    <Footer/>
  </div>
);

ContactUsPage.propTypes = {};

ContactUsPage.defaultProps = {};

export default ContactUsPage;
