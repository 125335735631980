import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './PostPage.module.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import queryString from 'query-string';
import axios from 'axios';

export class PostPage extends Component {
    constructor(props) {
        super(props);
        this.postId = props.match.params.id;
        this.state = {
            post: {},
            isLoaded: false
        }
        const queryParams = queryString.parse(this.props.location.search);
        this.prevPage = queryParams.prevPage;
    }

    componentDidMount () {
        axios.get('https://bio-cfd.com/wp-json/wp/v2/posts/' + this.postId + '?_embed')
            .then(res => {
                this.setState({
                    post: res.data,
                    isLoaded: true
                });
            })
            .catch(err => console.log(err))
    }

    render() {
        return (
            <div className={styles.PostPage}>
                <NavBar darkText style={{backgroundColor: "#fff"}} />
                <section className="bg-light py-10">
                    <div className="container">
                        {this.state.isLoaded ?
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-xl-8">
                                    <div className="single-post">
                                        <h1>{this.state.post.title.rendered}</h1>
                                        <div className="d-flex align-items-center justify-content-between mb-5">
                                            <div className="single-post-meta mr-4">
                                                <img className="single-post-meta-img" src={this.state.post._embedded.author[0].avatar_urls["48"]} />
                                                <div className="single-post-meta-details">
                                                    <div className="single-post-meta-details-name">{this.state.post._embedded.author[0].name}</div>
                                                    <div className="single-post-meta-details-date">{new Date(this.state.post.modified_gmt).toDateString()}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-post-text my-5">
                                            <p dangerouslySetInnerHTML={{__html: this.state.post.content.rendered}} />
                                            <hr className="my-5" />
                                            <div className="text-center"><a className="btn btn-transparent-dark" href={this.prevPage}>Back to Blog Listing</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : <div className="row justify-content-center mt-5 mb-10">
                            <div className="spinner-grow" style={{width: "3rem", height: "3rem"}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                          </div>}
                    </div>
                    <div className="svg-border-rounded text-dark">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}

PostPage.propTypes = {};

PostPage.defaultProps = {};

export default PostPage;
