import React from 'react';
import PropTypes from 'prop-types';
import styles from './AccountActivationPage.module.css';
import queryString from 'query-string';

import { authenticationService } from './../../_services';

class AccountActivationPage extends React.Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) { 
        this.props.history.push('/dashboard');
    }

    this.state = {
      sending: true,
      activated: false
    };

    const queryParams = queryString.parse(this.props.location.search);
    this.triggerActivationProcess = this.triggerActivationProcess.bind(this);
    this.code = queryParams.code;
  }

  componentDidMount() {
    if (this.code && this.state.sending) {
      this.triggerActivationProcess(this.code);
    }
  }

  triggerActivationProcess(code) {
    authenticationService.activate(code)
      .then(
          res => {
            if (res.status != 'fail') {
              this.setState({
                sending: false,
                activated: true
              });
            }
            else {
              this.setState({
                sending: false,
                activated: false
              });
            }
          },
          error => {
            this.setState({
              sending: false,
              activated: false
            });
          }
      );
  }

  render() {
    return (
      <div className={styles.LoginPage}>
        <div className="container">

          <div className="row justify-content-center">

              <div className="col-xl-12 col-lg-12 col-md-9">

                  <div className="card o-hidden border-0 shadow-lg my-20">
                      <div className="card-body p-0">
                          <div className="row">
                              <div className="col-lg-6 d-none d-lg-block bg-account-activation-image"></div>
                              <div className="col-lg-6">
                                   <div className="pt-5 px-5 pb-4">
                                      <div className="text-center">
                                        {this.code ?
                                        <h1 className="h4 text-gray-900 mb-4">Welcome back!</h1>
                                        :
                                        <h1 className="h4 text-gray-900 mb-4">Acount activation required</h1>}
                                      </div>
                                      {this.code ?
                                        this.state.sending
                                          ? (<div><p className="text-center">Activating your account</p>
                                           <div className="row justify-content-center">
                                                 <div className="spinner-grow" style={{width: "2.4rem", height: "2.4rem"}} role="status">
                                                     <span className="sr-only">Loading...</span>
                                                 </div>
                                             </div></div>)
                                           : this.state.activated? <div><h5 className="text-center">Congratulations, your account has been activated successfully.</h5>
                                           <div className="row justify-content-center"><i className="fa-10x fa fa-check-circle" aria-hidden="true"></i></div></div>
                                            : <div><h5 className="text-center">Sorry, your account couldn't be activated or may be already activated.</h5></div>
                                        :
                                        <div><p className="text-center">Please check your email to activate your account.</p></div>}
                                      
                                      <hr />
                                      {this.state.activated ? 
                                      <div className="text-center">
                                      <a className="small" href="/login">Back to login page</a>
                                  </div>
                                    :
                                    <div className="text-center">
                                          <a className="small" href="/register">Back to register page</a>
                                      </div>
                                    }
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
    );
  }
}

AccountActivationPage.propTypes = {};

AccountActivationPage.defaultProps = {};

export default AccountActivationPage;
