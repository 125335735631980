import React from 'react';
import PropTypes from 'prop-types';
import styles from './LandingPage.module.css';
import NavBar from './../../components/NavBar/NavBar';
import HeaderSection from './../../components/HeaderSection/HeaderSection';

const LandingPage = () => (
  <div className={styles.LandingPage}>
    <NavBar fixed trans darkText style={{backgroundColor: "#fff"}}/>
    <HeaderSection/>
  </div>
);

LandingPage.propTypes = {};

LandingPage.defaultProps = {};

export default LandingPage;
