import React from 'react';
import PropTypes from 'prop-types';
import styles from './UploadModelPage.module.css';
import WebAppSideNavBar from '../WebAppSideNavBar/WebAppSideNavBar';
import WebAppNavBar from '../WebAppNavBar/WebAppNavBar';
import { authenticationService } from '../../_services';
import axios from 'axios';

class UploadModelPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        uploaded: false,
        selectedFile: undefined,
        projectName: '',
        progress: 0
    };

    this.handleUploadClicked = this.handleUploadClicked.bind(this);
    this.handleFileChoosen = this.handleFileChoosen.bind(this);
    this.handleProjectNameChanged = this.handleProjectNameChanged.bind(this);
  }

  handleUploadClicked(ev) {
    ev.preventDefault();

    const data = new FormData();
    data.append('file', this.state.selectedFile);
    data.append('projectName', this.state.projectName);

    axios.post('http://15.236.91.33:443/meshData', data, {
        onUploadProgress: progressEvent => this.setState({progress: progressEvent.loaded/progressEvent.total}),
        headers: {
          'Authorization': 'Bearer ' + authenticationService.currentUserValue.auth_token,
          'Authorization_Source': authenticationService.currentUserValue.auth_token_source
        }
    })
    .then(response => {
      console.log('Response', response.data)
    })
    .catch(e => {
        console.log('Error: ', e.response)
    })
  }

  handleFileChoosen() {
    this.setState({selectedFile: this.uploadInput.files[0], progress:0})
  }

  handleProjectNameChanged(e) {
    this.setState({projectName: e.target.value})
  }

  render() {
    return (
  <div className={styles.Project}>
    <div id="wrapper">
        <WebAppSideNavBar/>
        <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">

                <WebAppNavBar/>
                <div className="container-fluid">

                    <div className="row">

                    <div className="col-xl-4 col-lg-5">
                            <div className="card shadow mb-4">
                                <div className="card-body">
                                    <div className="row pl-4">
                                      <h1 className="font-weight-bold-webapp text-primary-webapp">3D file</h1>
                                      <h1>&nbsp;Guidelines</h1>
                                    </div>
                                    <p>Here are just a few guidelines to keep in mind for your 3D file upload.</p>
                                    <ul>
                                      <li>Type: *.stl,* .STL</li>
                                      <li>Size: max 128MB</li>
                                    </ul>
                                    <hr/>
                                    <a href="/consultancy">Need help?</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-8 col-lg-7">
                            <div className="card shadow mb-4">
                                <div className="card-body">
                                  <label className="font-weight-bold-webapp input-required"
                                         htmlFor="projectName">Project Name</label>
                                  <input className="form-control form-control-lg mb-4" type="text"
                                         id="projectName" placeholder="Project name"
                                         value={this.state.projectName}
                                         onChange={(e) => this.handleProjectNameChanged(e)}/>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span onClick={this.handleUploadClicked} className="input-group-text btn-primary-webapp" id="inputGroupFileAddon01">
                                        Upload
                                      </span>
                                    </div>
                                    <div className="custom-file">
                                      <input
                                        type="file"
                                        ref={(ref) => { this.uploadInput = ref; }}
                                        className="custom-file-input"
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                        onChange={this.handleFileChoosen}
                                      />
                                      <label className="custom-file-label" htmlFor="inputGroupFile01">
                                        { this.state.selectedFile? this.state.selectedFile.name: 'Choose file' }
                                      </label>
                                    </div>
                                  </div>
                                  {this.state.progress != 0 && <div>
                                    <div className="row mt-4">
                                      <div className="col-auto font-weight-bold-webapp text-gray-600">Progress:</div>
                                      <div className="font-weight-bold-webapp text-gray-600 ml-auto mr-4">{(this.state.progress*100).toFixed(2)+'%'}</div>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar bg-success" role="progressbar" style={{width: `${this.state.progress*100}%`}}
                                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                  </div>}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; BIO-CFD 2020</span>
                    </div>
                </div>
            </footer>

        </div>

    </div>
  </div>
    ) 
}
}

UploadModelPage.propTypes = {};

UploadModelPage.defaultProps = {};

export default UploadModelPage;
