import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.css';
import * as Icon from 'react-feather';

const Footer = () => (
  <div className={styles.Footer}>
    <div id="layoutDefault_footer">
        <footer className="footer pt-10 pb-5 mt-auto bg-dark footer-dark">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="footer-brand">Bio-CFD</div>
                        <div className="mb-3">Computational fluid dynamics made easy</div>
                        <div className="icon-list-social mb-5">
                            <a className="icon-list-social-link" href="https://www.linkedin.com/company/bio-cfd/"><Icon.Linkedin/></a>
                            <a className="icon-list-social-link" href="https://twitter.com/BioCfd"><Icon.Twitter/></a>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="row">
                            <div className="offset-2 col-lg-6 col-md-6 mb-5 mb-lg-0">
                                <div className="text-uppercase-expanded text-xs mb-4">More info</div>
                                <ul className="list-unstyled mb-0">
                                    <li className="mb-2"><a href="/overview">Overview</a></li>
                                    <li className="mb-2"><a href="/knowledge">Knowledge</a></li>
                                    <li className="mb-2"><a href="/news">News</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="text-uppercase-expanded text-xs mb-4">Legal</div>
                                <ul className="list-unstyled mb-0">
                                    <li className="mb-2"><a href="#!">Privacy Policy</a></li>
                                    <li className="mb-2"><a href="#!">Terms and Conditions</a></li>
                                    <li><a href="#!">License</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="my-5" />
                <div className="row align-items-center">
                    <div className="col-md-6 small">Copyright &copy; Bio-CFD Inc. All rights reserved.</div>
                    <div className="col-md-6 text-md-right small">
                        <a href="#!">Privacy Policy</a>
                        &middot;
                        <a href="#!">Terms &amp; Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
