import React from 'react';
import PropTypes from 'prop-types';
import styles from './ConsultancyPage.module.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

const ConsultancyPage = () => (
  <div className={styles.ConsultancyPage}>
    <NavBar trans fixed darkText style={{backgroundColor: "#212832"}}/>
    <header class="page-header page-header-dark bg-primary pb-10 mb-5">
        <div class="page-header-content pt-5">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <h1 class="page-header-title mb-3">Bio-CFD can be the ultimate flow simulation arm to empower your project</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="svg-border-rounded text-light">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
        </div>
    </header>
    <section class="bg-light pb-5 pt-4">
        <div class="container">
          <p class="page-header-text text-justify">Whether you are a cardiologist simulating blood flow in a personalized stent, or an safety professional who to wants to evaluate COVID-19 airborne transmission risk, Bio-CFD can deliver versatile end-to-end CFD solutions within a scalable framework. Our robust vertical CFD approach offers unlimited access to flow simulation physics to non-specialized users. Our technology automates the intricate details of the CFD case curation and setup to offer more focus on the biomedical and/or pharmaceutical problem. We offer standard and tailored consultancy models to our clients, leave us an introductory message and someone from our team will contact you.</p>
          <form>
              <div class="form-row mt-5">
                  <div class="form-group col-md-6"><label class="text-dark input-required" for="inputName">Full name</label><input class="form-control py-4" id="inputName" type="text" placeholder="Full name" required/></div>
                  <div class="form-group col-md-6"><label class="text-dark input-required" for="inputEmail">Email</label><input class="form-control py-4" id="inputEmail" type="email" placeholder="name@example.com" required/></div>
              </div>
              <div class="form-group">
                <label class="text-dark" for="flexRadioDefault">What is your field of interest/work? </label>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Medicine (clinical practice)
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Life sciences (research)
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                    <label class="form-check-label" for="flexRadioDefault3">
                      Biomedical engineering (technology development)
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                    <label class="form-check-label" for="flexRadioDefault4">
                      Biomedical engineering (research)
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                    <label class="form-check-label" for="flexRadioDefault5">
                      Pharmaceuticals (industry/research)
                    </label>
                  </div>
              </div>
              <div class="form-group"><label class="text-dark input-required" for="inputMessage">Message</label><textarea class="form-control py-3" id="inputMessage" type="text" placeholder="Enter your message..." rows="4" required></textarea></div>
              <div class="text-center"><button class="btn btn-primary mt-4" type="submit">Submit Request</button></div>
          </form>
        </div>
        <div class="svg-border-rounded text-dark">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
        </div>
    </section>
    <Footer/>
  </div>
);

ConsultancyPage.propTypes = {};

ConsultancyPage.defaultProps = {};

export default ConsultancyPage;
