import React from 'react';
import PropTypes from 'prop-types';
import styles from './WebAppNavBar.module.css';
import { history } from '../../_helpers';
import { authenticationService } from '../../_services';

class WebAppNavBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleLogoutClicked = this.handleLogoutClicked.bind(this);
  }

  handleLogoutClicked() {
      authenticationService.logout();
      history.push('/login');
  }

  render() {
    return(
      <div className={styles.WebAppNavBar}>
        
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

          <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
              <i className="fa fa-bars"></i>
          </button>

          <ul className="navbar-nav ml-auto">

              <div className="topbar-divider d-none d-sm-block"></div>

              <li className="nav-item dropdown no-arrow">
                  <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span className="mr-2 d-none d-lg-inline text-gray-600 small">{`${authenticationService.currentUserValue.firstName} ${authenticationService.currentUserValue.lastName}`}</span>
                      <img className="img-profile rounded-circle"
                          src={authenticationService.currentUserValue.imageUrl ? authenticationService.currentUserValue.imageUrl : "./../../assets/img/illustrations/profiles/profile-5.png"} />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                      aria-labelledby="userDropdown">
                      <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          Logout
                      </a>
                  </div>
              </li>

          </ul>

          </nav>
          <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" type="button" data-dismiss="modal">Cancel</button>
                                <a className="btn btn-danger" data-dismiss="modal" onClick={this.handleLogoutClicked}>Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    );
  }
}
WebAppNavBar.propTypes = {};

WebAppNavBar.defaultProps = {};

export default WebAppNavBar;
