import React from 'react';
import PropTypes from 'prop-types';
import styles from './HeaderSection.module.css';
import Footer from './../../components/Footer/Footer';
import * as Icon from 'react-feather';

const HeaderSection = () => (
  <div className={styles.HeaderSection}>
      <header className="page-header page-header-dark bg-img-repeat bg-primary">
          <div className="page-header-content">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-xl-8 col-lg-10 text-center">
                          <h1 className="page-header-title">The first CFD platform for biomedical and life sciences</h1>
                          <a className="btn btn-teal font-weight-500 mr-2 mt-4" href="/overview">Learn more!</a>
                      </div>
                  </div>
              </div>
          </div>
          <div className="svg-border-waves text-light">
              <svg className="wave" style={{pointerEvents: 'none'}} fill="white" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 75">
                  <defs>
                  </defs>
                  <clippath id="a"><rect className="a" width="1920" height="75"></rect></clippath>
                  <g className="b"><path className="c" d="M1963,327H-105V65A2647.49,2647.49,0,0,1,431,19c217.7,3.5,239.6,30.8,470,36,297.3,6.7,367.5-36.2,642-28a2511.41,2511.41,0,0,1,420,48"></path></g>
                  <g className="b"><path className="d" d="M-127,404H1963V44c-140.1-28-343.3-46.7-566,22-75.5,23.3-118.5,45.9-162,64-48.6,20.2-404.7,128-784,0C355.2,97.7,341.6,78.3,235,50,86.6,10.6-41.8,6.9-127,10"></path></g>
                  <g className="b"><path className="d" d="M1979,462-155,446V106C251.8,20.2,576.6,15.9,805,30c167.4,10.3,322.3,32.9,680,56,207,13.4,378,20.3,494,24"></path></g>
                  <g className="b"><path className="d" d="M1998,484H-243V100c445.8,26.8,794.2-4.1,1035-39,141-20.4,231.1-40.1,378-45,349.6-11.6,636.7,73.8,828,150"></path></g>
              </svg>
          </div>
      </header>


      <section className="bg-white py-10">
        <div className="container">
            <div className="row text-center">
                <div className="col-lg-4 mb-5 mb-lg-0">
                    <div className="icon-stack icon-stack-xl bg-primary text-white mb-4"><Icon.Zap/></div>
                    <h3>Solve</h3>
                    <p className="mb-0">Use state of the art solvers based on OpenFOAM technology with LES turbulence modeling and FSI capabilities.</p>
                </div>
                <div className="col-lg-4 mb-5 mb-lg-0">
                    <div className="icon-stack icon-stack-xl bg-primary text-white mb-4"><Icon.Cpu/></div>
                    <h3>Analyze</h3>
                    <p className="mb-0">Extract and analyze hemodynamic, aerodynamic or hydrodynamic patterns and produce patient reports for clinical reference.</p>
                </div>
                <div className="col-lg-4">
                    <div className="icon-stack icon-stack-xl bg-primary text-white mb-4"><Icon.Terminal/></div>
                    <h3>Develope</h3>
                    <p className="mb-0">Join Bio-CFD community and be part of the project. Contribute codes, data, research and get access to our beta solvers.</p>
                </div>
            </div>
        </div>
        <div className="container mt-5">
            <div className="row justify-content-center">
                <a className="btn font-weight-500 btn-teal col-3" href="#">Find more<Icon.ArrowRight className="ml-1"/></a>
            </div>
        </div>
        <div className="svg-border-rounded text-light">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
        </div>
    </section>

    <section className="pt-10">
        <div className="container">
            <div className="row text-center">
                <div className="mb-5 text-lg-center">
                    <h1 className="font-weight-800">empowering future clinical applications of Computational Fluid Dynamics</h1>
                    <p className="lead">Bio-CFD includes optimized and tailored CFD tools designed to empower personalized medicine in vascular surgery, respirology, pulmonology and urology.</p>
                </div>
            </div>
        </div>
    </section>

      
      <section className="bg-light pb-5">
        <div className="container">
            <div className="row mt-5">
                <div className="col-md-6 col-xl-4 mb-5 mt-n5">
                    <a className="card card-portfolio h-auto sajed1" href="#!"
                        ><img className="card-img-top" src="/assets/img/1.png" alt="..." />
                        <div className="card-body"><div className="card-title">Solve</div></div></a>
                </div>
                <div className="col-md-6 col-xl-4 mb-5 mt-md-n5">
                    <a className="card card-portfolio h-auto sajed2" href="#!"
                        ><img className="card-img-top" src="/assets/img/2.png" alt="..." />
                        <div className="card-body"><div className="card-title">Analyze</div></div></a>
                </div>
                <div className="col-md-6 col-xl-4 mb-5 mt-xl-n5">
                    <a className="card card-portfolio h-auto sajed3" href="#!"
                        ><img className="card-img-top" src="/assets/img/3.png" alt="..." />
                        <div className="card-body"><div className="card-title">Develop</div></div></a>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6 mb-lg-n20 mb-5 mb-lg-0 z-1">
                    <a className="card text-decoration-none h-100 lift" href="#!"
                        ><div className="card-body pt-5">
                            <div className="d-flex align-items-center">
                                <div className="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0"><Icon.PenTool/></div>
                                <div className="ml-4">
                                    <h5 className="text-primary">Bio-CFD Reproducibility Index</h5>
                                    <p className="card-text text-gray-600">We promote reproducible CFD simulations in life sciences! Submit your simulation’s metadata now and receive a Unique Reproducibility Index (URI) to authenticate your CFD work!</p>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-4">
                                <div className="btn font-weight-500 btn-teal">Find more<Icon.ArrowRight className="ml-1"/></div>
                            </div>
                        </div></a>
                </div>
            </div>
        </div>
        <div className="svg-border-rounded text-light">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="white"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
        </div>
    </section>

    <section className="bg-light py-10 bg-white">
            <div className="container">
                <hr className="my-10" />
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2>Do you work with CFD in biomedical applications?</h2>
                        <p className="lead mb-5">Join our community and stay tuned!</p>
                    </div>
                </div>
                <form>
                    <div className="form-row">
                        <div className="form-group col-md-6"><label className="text-dark" htmlFor="inputName">Full name</label><input className="form-control py-4" id="inputName" type="text" placeholder="Full name" /></div>
                        <div className="form-group col-md-6"><label className="text-dark" htmlFor="inputEmail">Email</label><input className="form-control py-4" id="inputEmail" type="email" placeholder="name@example.com" /></div>
                    </div>
                    <div className="text-center"><button className="btn btn-teal mt-4" type="submit">Subscribe</button></div>
                </form>
            </div>
            <div className="svg-border-rounded text-dark">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
            </div>
        </section>

        <Footer/>
  </div>
);

HeaderSection.propTypes = {};

HeaderSection.defaultProps = {};

export default HeaderSection;
