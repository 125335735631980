import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReproducibilityIndex.module.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import * as Icon from 'react-feather';

const ReproducibilityIndex = () => (
  <div className={styles.ReproducibilityIndex}>
    <NavBar darkText style={{backgroundColor: "#fff"}} />
    <section className="bg-light pt-5 pb-10">
      <div class="container">
          <div class="d-flex align-items-center mb-5">
              <div class="ml-3">
                  <h2 class="mb-0">Bio-CFD Reproducibility Index</h2>
                  <p class="lead mb-0">In life science, Computational Fluid Dynamics (CFD) is a secondary research tool. It is used to simulate fluid flow phenomena that interact with the living systems in question. In virtually all biological, biomedical and pharmaceutical applications, CFD is used to simulate crucial physical variables predominantly affect the life-related variables. Therefore, it is crucial to ensure that the CFD simulations are reproducible. The Bio-CFD Reproducibility Index is a simple metadata archive for CFD simulations in life sciences. The index (Rj) assigns a value ranging from 0.9 to 1 to a fully reproducible simulation (FR-CFDSIM), 0.4 to 0.9 to a partially reproducible simulation (PR-CFDSIM), and 0 to 0.4 to an irreproducible simulation (IR-CFDSIM). Read the theory behind our index <a href="https://arxiv.org/abs/2101.04874">here</a>.</p>
              </div>
          </div>
          <div class="accordion accordion-faq mb-5" id="authAccordion">
              <div class="card border-bottom">
                  <div class="card-body">
                      <div class="accordion-faq-title">
                          <div class="mr-2">
                              <h4 class="mb-0">Guidelines</h4>
                              <p class="card-text text-gray-500">Also read:</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card accordion-faq-item">
                  <a class="card-header" id="authHeadingOne" data-toggle="collapse" data-target="#authCollapseOne" aria-expanded="true" aria-controls="authCollapseOne" href="javascript:void(0);"
                      ><div class="accordion-faq-item-heading">Unique Reproducibility Index (URI)<i class="accordion-faq-item-heading-arrow" data-feather="chevron-down"></i></div
                  ></a>
                  <div class="collapse show" id="authCollapseOne" aria-labelledby="authHeadingOne" data-parent="#authAccordion"><div class="card-body">The URI is a unique digital identifier that makes your CFD simulation metadata discoverable by search engines and metadata harvesters and accessible for the community. After your submit the form below, we normally send you the URI and its particulars within 48 hours.</div></div>
              </div>
              <div class="card accordion-faq-item">
                  <a class="card-header collapsed" id="authHeadingTwo" data-toggle="collapse" data-target="#authCollapseTwo" aria-expanded="true" aria-controls="authCollapseTwo" href="javascript:void(0);">
                      <div class="accordion-faq-item-heading">Submission Guidelines<i class="accordion-faq-item-heading-arrow" data-feather="chevron-down"></i></div>
                      <div class="text-gray-400 flex-shrink-0 text-right">
                      <div class="badge badge-red-soft badge-pill text-red">must read before submission</div>
                  </div>    
                  </a>
                  <div class="collapse" id="authCollapseTwo" aria-labelledby="authHeadingTwo" data-parent="#authAccordion">
                    <div class="card-body pl-5 py-4">
                      <div className = "row">
                        <Icon.CheckCircle className="col-auto"/>
                        <p className="col">We collect the name and affiliation of the simulation registrar to issue the URI and help you authenticate the reproducibility of your CFD simulation. The simulation registrar is often the person who is in charge of the CFD simulation process as a whole and responsible for the originality and authenticity of the simulation and results. It is not restricted to principle investigators. The names and affiliations of other collaborators are not required.</p>
                      </div>
                      <div>
                        <div className = "row">
                          <Icon.CheckCircle className="col-auto"/>
                          <p className="col"><h6>What is CFD simulation metadata?</h6>Our metadata structure contains fields for CFD simulations in the broadest sense of life science applications. These fields are:</p>
                        </div>
                        <div className="row">
                          <div className="col offset-1">
                            <ul>
                              <li>Simulation goal: Describe the goal of your simulation in a simple way to help non-specialized audience to understand it. Limited to 400 characters in length.</li>
                              <li>Findings: Describe the goal of your simulation in a simple way to help non-specialized audience to understand it. Limited to 1000 characters in length.</li>
                              <li>Funding: Acknowledge any funding source relevant to this CFD simulation. Type None if no funding was received for this work.</li>
                              <li>Numerical method: FVM, FEM, LBM…etc</li>
                              <li>Governing equations</li>
                              <li>Turbulence formulation</li>
                              <li>Multiphase flow formulation</li>
                              <li>Fluid structure interaction formulation</li>
                              <li>Any other constitutive or transport equations and models</li>
                          </ul>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className = "row">
                          <Icon.CheckCircle className="col-auto"/>
                          <p className="col"><h6>Host your own files!</h6>In the submission process, you will be required to provide URLs to three files to support your request for reproducibility index. prepare and host these three files on Google Drive or any file hosting server before filling up the submission form. The URLs must provide download access to these files. All URLs will not be available for public access and will be used to calculate the reproducibility index.</p>
                        </div>
                        <div className="row">
                          <div className="col offset-1">
                            <ul>
                              <li>Provide a URL to download a schematic drawing of the computational domain with complete dimensions, initial and boundary condition locations and details, and all other essential details for other researchers to reproduce it. You may host the file on Google Drive or any other file hosting service. Only PDF format is accepted.</li>
                              <li>Provide a URL to a digital version of your computational domain. This URL will not be available for public access and will strictly be used for calculating the reproducibility index. Although not a required field, providing access to a working geometry file adds extra 20 points to the index. You may host the file on Google Drive or any other file hosting service. Only STL format is accepted.</li>
                              <li>Provide a URL to the validation and results of your CFD simulation. Each figure must have clear and informative caption. You may also want to provide digital working versions of the figures (in CSV, ASCII or similar formats) to facilitate reproduction. Compress your validation and results in PDF, CSV, ASCII, TXT, or DAT formats and upload it to a file hosting server (such as Google Drive) and enter the download URL below.</li>
                          </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <hr class="my-5" />
          <div style={{height: "80vh"}}>
            <iframe height="100%" className="embed-responsive" src="https://docs.google.com/forms/d/e/1FAIpQLSdJMK2RJu0-VQ_kk7sJoHWQZRPFiDkPgxp0_3anhPLnXjVzhQ/viewform?embedded=true" frameborder="0"></iframe>
          </div>
      </div>
      <div class="svg-border-rounded text-dark">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
      </div>
    </section>
    <Footer/>
  </div>
);

ReproducibilityIndex.propTypes = {};

ReproducibilityIndex.defaultProps = {};

export default ReproducibilityIndex;
