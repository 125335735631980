import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavBar.module.css';
import * as Icon from 'react-feather';

const NavBar = (props) => (
  <div className={styles.NavBar}>
      <nav className={"navbar navbar-marketing navbar-expand-lg navbar-light" + (props.fixed ? " fixed-top": "") + (props.trans ? " bg-transparent": "")} style={props.style}>
          <div className="container">
              <a className={"navbar-brand font-weight-bolder" + (props.darkText ? " text-dark": " text-white")} href="/">
                <img src="/assets/img/bio-cfd-logo.png" className="mt-1" alt="Bio-CFD"/>
              </a>
              <span className={"hidden-sm" + (props.darkText ? " text-dark": " text-white")}>Cloud CFD for biomedical applications</span>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><Icon.Menu style={{ color: props.darkText ? 'black' : ''}} /></button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto mr-lg-5">
                      <li className="nav-item dropdown no-caret">
                          <a className={"nav-link dropdown-toggle font-weight-bolder" + (props.darkText ? " text-dark": " text-white")} id="navbarDropdownDocs" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More info<i className="fas fa-chevron-right dropdown-arrow"></i></a>
                          <div className="dropdown-menu dropdown-menu-right animated--fade-in-up" aria-labelledby="navbarDropdownDocs">
                              <a className="dropdown-item py-3" href="/overview"
                                  ><div className="icon-stack bg-primary-soft text-primary mr-4"><Icon.Cloud /></div>
                                  <div>
                                      <div className="small text-gray-500">Overview</div>
                                      What we do.
                                  </div></a>
                              <div className="dropdown-divider m-0"></div>
                              <a className="dropdown-item py-3" href="/knowledge"
                                  ><div className="icon-stack bg-primary-soft text-primary mr-4"><Icon.BookOpen /></div>
                                  <div>
                                      <div className="small text-gray-500">Knowledge</div>
                                      Did you know ?
                                  </div></a>
                              <div className="dropdown-divider m-0"></div>
                              <a className="dropdown-item py-3" href="/news"
                                  ><div className="icon-stack bg-primary-soft text-primary mr-4"><Icon.Bell /></div>
                                  <div>
                                      <div className="small text-gray-500">News</div>
                                      Don't Miss!
                                  </div></a>
                          </div>
                      </li>
                      <li className="nav-item dropdown no-caret">
                          <a className={"nav-link dropdown-toggle font-weight-bolder" + (props.darkText ? " text-dark": " text-white")} id="navbarDropdownDocs" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Contact us<i className="fas fa-chevron-right dropdown-arrow"></i></a>
                          <div className="dropdown-menu dropdown-menu-right animated--fade-in-up" aria-labelledby="navbarDropdownDocs">
                              <a className="dropdown-item py-3" href="/team"
                                  ><div className="icon-stack bg-primary-soft text-primary mr-4"><Icon.Info /></div>
                                  <div>
                                      <div className="small text-gray-500">Meet our team</div>
                                      Who are we ?
                                  </div></a>
                              <div className="dropdown-divider m-0"></div>
                              <a className="dropdown-item py-3" href="/consultancy"
                                  ><div className="icon-stack bg-primary-soft text-primary mr-4"><Icon.HelpCircle /></div>
                                  <div>
                                      <div className="small text-gray-500">Consultancy</div>
                                      Need help ?
                                  </div></a>
                          </div>
                      </li>
                  </ul>
                  <a className="btn font-weight-500 ml-lg-4 btn-teal" href="/dashboard">Join now<i className="ml-2" data-feather="arrow-right"></i></a>
              </div>
          </div>
      </nav>
  </div>
);

NavBar.propTypes = {};

NavBar.defaultProps = {};

export default NavBar;
