import React from 'react';
import PropTypes from 'prop-types';
import styles from './Project.module.css';
import { history } from '../../_helpers';
import { authenticationService } from '../../_services';
import createPlotlyComponent from 'react-plotly.js/factory';
import WebAppSideNavBar from '../WebAppSideNavBar/WebAppSideNavBar';
import WebAppNavBar from '../WebAppNavBar/WebAppNavBar';

const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);
class Project extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        imageURL: '',
        selectedItem: 0
    };

    this.handleUploadImage = this.handleUploadImage.bind(this);
  }

  handleUploadImage(ev) {
    ev.preventDefault();

    const data = new FormData();
    data.append('file', this.uploadInput.files[0]);

    fetch('http://15.236.91.33:443/meshData', {
      method: 'POST',
      body: data,
    }).then((response) => {
      response.json().then((body) => {
        this.setState({ imageURL: `${body.file}` });
      });
    });
  }

  render() {

    var intensity = [0, 0.14285714285714285, 0.2857142857142857, 0.42857142857142855, 0.5714285714285714, 0.7142857142857143, 0.8571428571428571, 1];

    var data = this.props.data
    ?   [{
            type: "mesh3d",
            x: JSON.parse(this.props.data.x),
            y: JSON.parse(this.props.data.y),
            z: JSON.parse(this.props.data.z),
            i: JSON.parse(this.props.data.i),
            j: JSON.parse(this.props.data.j),
            k: JSON.parse(this.props.data.k),
            intensity: intensity,
        }]
    : null;

    return (
  <div className={styles.Project}>
    <div id="wrapper">
        <WebAppSideNavBar/>
        <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">

                <WebAppNavBar/>
                <div className="container-fluid">

                    {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Project</h1>
                        <a onChange={this.onChangeFile} className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
                                className="fas fa-upload fa-sm text-white-50"></i> Upload file </a>
                        <form>
                            <div>
                            <input ref={(ref) => { this.uploadInput = ref; }} type="file" />
                            </div>
                            <br />
                            <div>
                            <button onClick={this.handleUploadImage}>Upload</button>
                            </div>
                        </form>
                    </div> */}

                    <div className="row">

                    <div className="col-xl-4 col-lg-5">
                            <div className="card shadow mb-4">
                                <div
                                    className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary">Configurations</h6>
                                </div>
                                <div className="card-body">
                                    <div className="chart-pie pt-4 pb-2">
                                        <canvas id="myPieChart"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-8 col-lg-7">
                            <div className="card shadow mb-4">
                                <div
                                    className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary">3D plot</h6>
                                </div>
                                <div className="">
                                    <div className="">
                                        {! data
                                        ? (<div className="row justify-content-center mt-5 mb-10">
                                                <div className="spinner-grow" style={{width: "3rem", height: "3rem"}} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>)
                                        : (
                                        <Plot
                                            data={data}
                                            style = {{width: "100%"}}
                                            layout={{autosize: true}}
                                            useResizeHandler= {true}
                                        />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; BIO-CFD 2020</span>
                    </div>
                </div>
            </footer>

        </div>

    </div>
  </div>
    ) 
}
}

Project.propTypes = {};

Project.defaultProps = {};

export default Project;
