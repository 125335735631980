import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ListPosts.module.css';
import axios from 'axios';

export class ListPosts extends Component {
  constructor(props) {
      super(props);
      this.state = {
          posts: [],
          isLoaded: false,
          totalPostsNum: 0,
      }
      const queryParams = props.queryParams;
      this.perPage = Number(queryParams.perPage ? queryParams.perPage : 5);
      this.currentPage = Number(queryParams.page ? queryParams.page : 1);
      this.sourcePath = props.sourcePath;

      this.setPageNumber = this.setPageNumber.bind(this);
  }

  componentDidMount () {
    this.loadData();
  }

  loadData(){
    console.log(this.props.baseUrl)
    axios.get(this.props.baseUrl + '&per_page=' + this.perPage + "&page=" + this.currentPage)
        .then(res => {
            this.setState({
                posts: res.data,
                isLoaded: true,
                totalPostsNum: res.headers['x-wp-total']
            });
        })
        .catch(err => console.log(err))
  }

  setPageNumber(pageNumber) {
    this.currentPage = pageNumber;
    this.setState({isLoaded: false});
    this.loadData();
  }

  render() {
      console.log(this.state);
      var parser = new DOMParser();
      var pagination = [];
      var pagesRange = [];
      var numberOfPages = Math.ceil(this.state.totalPostsNum/this.perPage);
      for (let i = Math.max(1, this.currentPage - 1); i <= Math.min(numberOfPages, this.currentPage + 1); i++) {
        pagesRange.push(i);
      }
      var prevArrow = (
          <li key="0" className={ "page-item pointer" + (this.currentPage == 1 ? " disabled" : "")}>
              <a className="page-link summary" onClick={() => this.setPageNumber(this.currentPage - 1)} aria-label="Previous"><span aria-hidden="true">«</span></a>
          </li>
      );
      pagination.push(prevArrow);
      if (pagesRange[0] > 2) {
          pagination.push(<li key="1" className="page-item pointer"><a className="page-link" onClick={() => this.setPageNumber(1)}>1</a></li>);
          pagination.push(<li key="-1" className="page-item disabled"><a className="page-link">...</a></li>);
      }
      for (const i of pagesRange) {
          var item = (
              <li key={i} className={"page-item pointer" + (i == this.currentPage ? " active" : "")}><a className="page-link" onClick={() => this.setPageNumber(i)}>{i}</a></li>
          );
          pagination.push(item);
      }
      if (pagesRange[pagesRange.length - 1] < numberOfPages - 1) {
            pagination.push(<li key="-2" className="page-item disabled"><a className="page-link">...</a></li>);
            pagination.push(<li key={numberOfPages} className="page-item pointer"><a className="page-link" onClick={() => this.setPageNumber(numberOfPages)}>{numberOfPages}</a></li>);
      }
      var nextArrow = (
          <li key={numberOfPages+1} className={ "page-item" + (this.currentPage == numberOfPages ? " disabled" : "")}>
              <a className="page-link pointer" onClick={() => this.setPageNumber(this.currentPage + 1)} aria-label="Next"><span aria-hidden="true">»</span></a>
          </li>
      );
      pagination.push(nextArrow);
      return (
          <div className={styles.NewsPage + " mt-5"}>
              <nav aria-label="Page navigation example">
                  <ul className="pagination pagination-blog justify-content-center">
                      {pagination}
                  </ul>
              </nav>
              <section className="bg-light py-5">
                  <div className="container">
                      {this.state.isLoaded
                        ? this.state.posts.map((post, index) => {
                            return (
                                <a key={index} className="card post-preview post-preview-featured lift mb-5" href={"posts/" + post.id + "?prevPage=" + this.sourcePath}
                                    ><div className="row no-gutters">
                                        {
                                            parser.parseFromString(post.content.rendered, 'text/html').getElementsByTagName('img').length > 0 
                                                ? <div className="col-lg-4"><div className="post-preview-featured-img" style={{backgroundImage: "url('" + parser.parseFromString(post.content.rendered, 'text/html').getElementsByTagName('img')[0].src + "')"}}></div></div>
                                                : ''
                                        }
                                        <div className={parser.parseFromString(post.content.rendered, 'text/html').getElementsByTagName('img').length > 0 ? "col-lg-8" : "col-lg-12"}>
                                            <div className="card-body">
                                                <div className="py-2 px-2">
                                                    <h5 className="card-title">{post.title.rendered}</h5>
                                                    <p className="card-text" dangerouslySetInnerHTML={{__html: post.excerpt.rendered}} />
                                                </div>
                                                <hr />
                                                <div className="post-preview-meta">
                                                    <img className="post-preview-meta-img" src={post._embedded.author[0].avatar_urls["48"]} />
                                                    <div className="post-preview-meta-details">
                                                        <div className="post-preview-meta-details-name">{post._embedded.author[0].name}</div>
                                                        <div className="post-preview-meta-details-date">{new Date(post.modified_gmt).toDateString()}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            )})
                        : <div className="row justify-content-center mt-5 mb-10">
                            <div className="spinner-grow" style={{width: "3rem", height: "3rem"}} role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        }
                  </div>
                  <div className="svg-border-rounded text-dark">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                  </div>
              </section>
          </div>
      )
  }
}

ListPosts.propTypes = {};

ListPosts.defaultProps = {};

export default ListPosts;
