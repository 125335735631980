import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './NewsPage.module.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import queryString from 'query-string';
import ListPosts from '../ListPosts/ListPosts';

const NewsPage = (props) => (
    <div className={styles.KnowledgePage}>
      <NavBar darkText style={{backgroundColor: "#fff"}} />
      <header className="page-header page-header-dark bg-img-cover overlay overlay-60 mb-3" style={{backgroundImage: "url(https://source.unsplash.com/PTRzqc_h1r4/1600x900)"}}>
          <div className="page-header-content">
              <div className="container text-center">
                  <div className="row justify-content-center">
                      <div className="col-lg-8">
                          <h1 className="page-header-title mb-3">News</h1>
                          <p className="page-header-text mb-0">Browse articles, keep up to date, and learn more on our blog!</p>
                      </div>
                  </div>
              </div>
          </div>
          <div className="svg-border-rounded text-light">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
          </div>
      </header>
      <ListPosts sourcePath="/news" baseUrl="https://bio-cfd.com/wp-json/wp/v2/posts?_embed" queryParams={queryString.parse(props.location.search)}/>
      <Footer/>
    </div>
  );

NewsPage.propTypes = {};

NewsPage.defaultProps = {};

export default NewsPage;
