import React from 'react';
import PropTypes from 'prop-types';
import styles from './ForgetPasswordPage.module.css';
import axios from 'axios';

class ForgetPasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            email: '',
            loaded: false,
            sending: false,
            success: false 
        }
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handleResetClicked = this.handleResetClicked.bind(this);
    }

    handleEmailChanged(e) {
        this.setState({email: e.target.value});
    }

    handleResetClicked() {
        this.setState({sending: true});
        axios.post('http://15.236.91.33:443/auth/forgetPassword', {email: this.state.email})
        .then(response => {
            if (response.status != 'fail') {
                this.setState({
                    loaded: true,
                    sending: false,
                    success: true});
            }
            else {
                this.setState({
                    loaded: true,
                    sending: false,
                    success: false});
            }
            console.log('Response', response.data)
        })
        .catch(e => {
            this.setState({
                loaded: true,
                sending: false});
            console.log('Error: ', e.response)
        })
    }

    render() {
        return (
            <div className={styles.ForgetPasswordPage}>
            <div class="container">
                <div class="row justify-content-center">
        
                    <div class="col-xl-12 col-lg-12 col-md-9">
        
                        <div class="card o-hidden border-0 shadow-lg my-20">
                            <div class="card-body p-0">
                                <div class="row">
                                    <div class="col-lg-6 d-none d-lg-block bg-password-image"></div>
                                    <div class="col-lg-6">
                                        <div class="p-5">
                                            <div class="text-center">
                                                <h1 class="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
                                                <p class="mb-4">We get it, stuff happens. Just enter your email address below
                                                    and we'll send you a link to reset your password!</p>
                                            </div>
                                            {this.state.sending ?
                                                <div className="row justify-content-center">
                                                <div className="spinner-grow" style={{width: "2.4rem", height: "2.4rem"}} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        :
                                        this.state.loaded ?
                                                this.state.success ?
                                            <h4 className="text-center"> Please check you email to reset your password.</h4>
                                            : <form class="user">
                                                <p className="text-center text-red">An error occured or the entered email is not valid</p>
                                            <div class="form-group">
                                                <input onChange={this.handleEmailChanged} type="email" class="form-control form-control-user"
                                                    id="exampleInputEmail" aria-describedby="emailHelp" value={this.state.email}
                                                    placeholder="Enter Email Address..." />
                                            </div>
                                            <a onClick={this.handleResetClicked} class="btn btn-primary btn-user btn-block">
                                                Reset Password
                                            </a>
                                        </form>
                                            :<form class="user">
                                                <div class="form-group">
                                                    <input onChange={this.handleEmailChanged} type="email" class="form-control form-control-user"
                                                        id="exampleInputEmail" aria-describedby="emailHelp" value={this.state.email}
                                                        placeholder="Enter Email Address..." />
                                                </div>
                                                <a onClick={this.handleResetClicked} class="btn btn-primary btn-user btn-block">
                                                    Reset Password
                                                </a>
                                            </form>
                                        }
                                            <hr />
                                            <div class="text-center">
                                                <a class="small" href="/register">Create an Account!</a>
                                            </div>
                                            <div class="text-center">
                                                <a class="small" href="/login">Already have an account? Login!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </div>
        
                </div>
        
            </div>
          </div>
        );
    }
}

ForgetPasswordPage.propTypes = {};

ForgetPasswordPage.defaultProps = {};

export default ForgetPasswordPage;
