import React from 'react';
import PropTypes from 'prop-types';
import styles from './WebAppSideNavBar.module.css';

class WebAppSideNavBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

        <a className="sidebar-brand d-flex align-items-center justify-content-left row" href="#">
            <div className="sidebar-brand-icon col-4">
                <img style={{width: '2rem', marginLeft: '1.2rem'}} src="./../../logo.png" />
            </div>
            <div className="sidebar-brand-text">BIO-CFD</div>
        </a>

        <hr className="sidebar-divider my-0" />

        <li className="nav-item active">
            <a className="nav-link" href="/dashboard">
                <i className="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span></a>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">
            Utilities
        </div>

        <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                <i className="fas fa-fw fa-cog"></i>
                <span>Settings</span>
            </a>
            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Customizations:</h6>
                    <a className="collapse-item" href="#">Preferences</a>
                </div>
            </div>
        </li>

        <hr className="sidebar-divider d-none d-md-block" />

        <div className="text-center d-none d-md-inline">
            <button className="rounded-circle border-0" id="sidebarToggle"></button>
        </div>

      </ul>
    );
  }
}

WebAppSideNavBar.propTypes = {};

WebAppSideNavBar.defaultProps = {};

export default WebAppSideNavBar;
