import React from 'react';
import PropTypes from 'prop-types';
import styles from './OverviewPage.module.css';
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

const OverviewPage = () => (
  <div className={styles.OverviewPage}>
    <NavBar trans fixed darkText style={{backgroundColor: "#212832"}}/>
    <header class="page-header page-header-dark bg-primary">
        <div class="page-header-content pt-5">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <h1 class="page-header-title mb-3">Overview</h1>
                        <p class="page-header-text">Here are some answers to some commonly asked questions</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="svg-border-rounded text-light">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
        </div>
    </header>
    <section class="bg-light py-10">
        <div class="container">
            <div class="d-flex align-items-center mb-5">
                <div class="ml-3">
                    <h2 class="mb-0">What is BIO-CFD:</h2>
                    <p class="lead mb-0">Bio-CFD is a cloud-based Computational Fluid Dynamics (CFD) platform specialized in biomedical applications in medicine and life sciences. The platform is built using OpenFOAM technology and employs multiple databases, libraries, boundary conditions and physical models that streamlines and empowers rapid and reliable flow simulation. The platform is developed to empower emerging clinical applications such as personalized medicine, surgical simulations and high-efficacy medical devices. Its technology is optimized for patient-specific simulations with clinically-relevant reporting capabilities. Bio-CFD empowers clinicians and researchers who require CFD simulations in numerous areas such as vascular medicine, pulmonology, respirology and urology.</p>
                </div>
            </div>
            <div class="accordion accordion-faq mb-5" id="authAccordion">
                <div class="card border-bottom">
                    <div class="card-body">
                        <div class="accordion-faq-title">
                            <div class="mr-2">
                                <h4 class="mb-0">Medical overview</h4>
                                <p class="card-text text-gray-500">From a medical point of view.</p>
                            </div>
                            <div class="badge badge-success-soft badge-pill text-success">3 Answers</div>
                        </div>
                    </div>
                </div>
                <div class="card accordion-faq-item">
                    <a class="card-header" id="authHeadingOne" data-toggle="collapse" data-target="#authCollapseOne" aria-expanded="true" aria-controls="authCollapseOne" href="javascript:void(0);"
                        ><div class="accordion-faq-item-heading">CFD for Vascular Hemodynamics.<i class="accordion-faq-item-heading-arrow" data-feather="chevron-down"></i></div
                    ></a>
                    <div class="collapse show" id="authCollapseOne" aria-labelledby="authHeadingOne" data-parent="#authAccordion"><div class="card-body">Vascular blood flow contributes to the genesis and development of cardiovascular disease (CVD). Hemodynamics is the study of vascular blood flow to better understand the development, diagnosis and therapy of CVD. CFD has been the primary tool for investigating vascular hemodynamics. Our technology provides custom solvers for different problems and settings encountered in this field. Bio-CFD provides dedicated solvers and validation benchmarks for the analysis of intracranial aneurysm, stenosis and atherosclerosis. Our technology offers companion diagnosis and surgical planning tools to empower next-generation CVD management techniques.</div></div>
                </div>
                <div class="card accordion-faq-item">
                    <a class="card-header collapsed" id="authHeadingTwo" data-toggle="collapse" data-target="#authCollapseTwo" aria-expanded="true" aria-controls="authCollapseTwo" href="javascript:void(0);"
                        ><div class="accordion-faq-item-heading">Pharmaceutical CFD<i class="accordion-faq-item-heading-arrow" data-feather="chevron-down"></i></div
                    ></a>
                    <div class="collapse" id="authCollapseTwo" aria-labelledby="authHeadingTwo" data-parent="#authAccordion"><div class="card-body">CFD is essential to the design and development processes of drug delivery devices and industrial pharmaceutical systems. Our technology tackles this sector by providing ready-to-use solvers to simulate the aerodynamics of dry powder inhalers, industrial cyclones and gas-based mixers.</div></div>
                </div>
                <div class="card accordion-faq-item">
                    <a class="card-header collapsed" id="authHeadingThree" data-toggle="collapse" data-target="#authCollapseThree" aria-expanded="true" aria-controls="authCollapseThree" href="javascript:void(0);"
                        ><div class="accordion-faq-item-heading">Patient-specific Simulations<i class="accordion-faq-item-heading-arrow" data-feather="chevron-down"></i></div
                    ></a>
                    <div class="collapse" id="authCollapseThree" aria-labelledby="authHeadingThree" data-parent="#authAccordion"><div class="card-body">Bio-CFD is equipped with many utilities to enable users to import patient-specific data from diagnostic systems such as MRI, CT and DSA. Our technology facilitates patient-specific diagnosis and surgical planning.</div></div>
                </div>
            </div>
            <div class="accordion accordion-faq" id="billingAccordion">
                <div class="card border-bottom">
                    <div class="card-body">
                        <div class="accordion-faq-title">
                            <div class="mr-2">
                                <h4 class="mb-0">Tech overview</h4>
                                <p class="card-text text-gray-500">From a tech point of view</p>
                            </div>
                            <div class="badge badge-success-soft badge-pill text-success">4 Answers</div>
                        </div>
                    </div>
                </div>
                <div class="card accordion-faq-item">
                    <a class="card-header collapsed" id="billingHeadingOne" data-toggle="collapse" data-target="#billingCollapseOne" aria-expanded="true" aria-controls="billingCollapseOne" href="javascript:void(0);"
                        ><div class="accordion-faq-item-heading">Technology Outlines<i class="accordion-faq-item-heading-arrow" data-feather="chevron-down"></i></div
                    ></a>
                    <div class="collapse" id="billingCollapseOne" aria-labelledby="authHeadingOne" data-parent="#billingAccordion"><div class="card-body">The core philosophy of our technology aims at empowering healthcare professionals to carry on CFD simulations seamlessly without prior knowledge of CFD algorithms and codes. We are building state-of-the art solvers with validation benchmarks for almost every problem encountered in biomedical and pharmaceutical applications. Our technology utilizes OpenFOAM on parallel CPU infrastructure easily accessible on the cloud with a user-friendly web front. The intricate details, usually encountered in status quo CFD solvers are simplified in our comprehensive library of boundary conditions, solver options and post-processing object functions. Our library is continuously updated to include new established measures and techniques contributed by many research groups around the world.</div></div>
                </div>
                <div class="card accordion-faq-item">
                    <a class="card-header collapsed" id="billingHeadingTwo" data-toggle="collapse" data-target="#billingCollapseTwo" aria-expanded="true" aria-controls="billingCollapseTwo" href="javascript:void(0);"
                        ><div class="accordion-faq-item-heading">Databases and Libraries<i class="accordion-faq-item-heading-arrow" data-feather="chevron-down"></i></div
                    ></a>
                    <div class="collapse" id="billingCollapseTwo" aria-labelledby="authHeadingTwo" data-parent="#billingAccordion"><div class="card-body">We provide a plethora of databases and libraries to cover all biomedical applications. The validation database includes experimentally validated models for vascular hemodynamics and pharmaceutical applications. Bio-CFD also provides a python interface for the HaeMod database of vascular hemodynamics with boundary conditions for thousands of virtual patients covering 11 arteries in the human body. We also provide a library of viscosity and turbulence models to empower different scenarios found in real world applications. Our library of pharmaceutical applications include established simulations for status-quo dry powder inhalers and other devices.</div></div>
                </div>
                <div class="card accordion-faq-item">
                    <a class="card-header collapsed" id="billingHeadingThree" data-toggle="collapse" data-target="#billingCollapseThree" aria-expanded="true" aria-controls="billingCollapseThree" href="javascript:void(0);"
                        ><div class="accordion-faq-item-heading">Cloud Computing<i class="accordion-faq-item-heading-arrow" data-feather="chevron-down"></i></div
                    ></a>
                    <div class="collapse" id="billingCollapseThree" aria-labelledby="authHeadingThree" data-parent="#billingAccordion"><div class="card-body">Our code runs on massively parallel CPU infrastructure with convenient job scheduling and task management modules.</div></div>
                </div>
                <div class="card accordion-faq-item">
                    <a class="card-header collapsed" id="billingHeadingThree" data-toggle="collapse" data-target="#billingCollapseThree" aria-expanded="true" aria-controls="billingCollapseThree" href="javascript:void(0);"
                        ><div class="accordion-faq-item-heading">Launch Plan<i class="accordion-faq-item-heading-arrow" data-feather="chevron-down"></i></div
                    ></a>
                    <div class="collapse" id="billingCollapseThree" aria-labelledby="authHeadingThree" data-parent="#billingAccordion"><div class="card-body">Bio-CFD is currently under development as of September 2020. The first solver, NeuroFOAM, will be launched in early November 2020. NeuroFOAM is a solver for simulating intracranial aneurysm, stenosis and stroke scenarios. Bio-CFD is planning to launch a seed-funding round in early 2021.</div></div>
                </div>
            </div>
            <hr class="my-5" />
        </div>
        <div class="svg-border-rounded text-dark">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
        </div>
    </section>
    <Footer/>
  </div>
);

OverviewPage.propTypes = {};

OverviewPage.defaultProps = {};

export default OverviewPage;
