import React from 'react';
import PropTypes from 'prop-types';
import LandingPage from './../LandingPage/LandingPage';
import OverviewPage from './../OverviewPage/OverviewPage';
import ReproducibilityIndex from './../ReproducibilityIndex/ReproducibilityIndex';
import ContactUsPage from './../ContactUsPage/ContactUsPage';
import ConsultancyPage from './../ConsultancyPage/ConsultancyPage';
import KnowledgePage from './../KnowledgePage/KnowledgePage';
import NewsPage from './../NewsPage/NewsPage';
import PostPage from './../PostPage/PostPage';
import { Route } from 'react-router-dom';
import './WebSite.css';

const WebSite = () => (
  <div>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/overview" component={OverviewPage} />
      <Route exact path="/bio-cfd-reproducibility-index" component={ReproducibilityIndex} />
      <Route exact path="/team" component={ContactUsPage} />
      <Route exact path="/consultancy" component={ConsultancyPage} />
      <Route exact path="/knowledge" component={KnowledgePage} />
      <Route exact path="/news" component={NewsPage} />
      <Route exact path="/posts/:id" component={PostPage} />
  </div>
);

WebSite.propTypes = {};

WebSite.defaultProps = {};

export default WebSite;
