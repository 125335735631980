import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoginPage.module.css';
import { authenticationService } from './../../_services';
import { GoogleLogin } from 'react-google-login';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) { 
        this.props.history.push('/dashboard');
    }
    this.state = {
      email: '',
      password: '',
      isSending: false
    };

    this.rememberMeRef= React.createRef();

    this.handleLoginClicked = this.handleLoginClicked.bind(this);
    this.handleEmailChanged = this.handleEmailChanged.bind(this);
    this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
    this.onGoogleSuccess = this.onGoogleSuccess.bind(this);
    this.onGoogleFailure = this.onGoogleFailure.bind(this);
  }

  handleEmailChanged(event) {
    this.setState({email: event.target.value});
  }

  handlePasswordChanged(event) {
    this.setState({password: event.target.value});
  }

  handleOnGoogleSuccessResp(res) {
    let user = {
      'firstName': res.profileObj.givenName,
      'lastName': res.profileObj.familyName,
      'email': res.profileObj.email,
      'imageUrl': res.profileObj.imageUrl,
      'auth_token_source': 'GOOGLE',
      'auth_token': res.tokenId
    }
    localStorage.setItem('currentUser', JSON.stringify(user));
    authenticationService.subscribeUser(user);
  }

  onGoogleSuccess(res) {
    console.log(res);

    this.handleOnGoogleSuccessResp(res)
    const { from } = this.props.location.state || { from: { pathname: "/dashboard" } };
    this.props.history.push(from);
    this.refreshTokenSetup(res);
  }

  refreshTokenSetup(res) {
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
    const refreshToken = async() => {
      const newAuthRes = await res.reloadAuthResponse();
      this.handleOnGoogleSuccessResp(newAuthRes)
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
      console.log('newAuthRes:', newAuthRes);
      console.log('new auth Token:', newAuthRes.id_token);
      setTimeout(refreshToken, refreshTiming);
    }
    setTimeout(refreshToken, refreshTiming);
  }

  onGoogleFailure(res) {
    console.log(res);
  }

  handleLoginClicked() {
    this.setState({isSending: true});
    authenticationService.login(this.state.email, this.state.password, this.rememberMeRef.current.checked)
      .then(
          user => {
              this.setState({
                email: '',
                password: '',
                isSending: false
              });
              const { from } = this.props.location.state || { from: { pathname: "/dashboard" } };
              this.props.history.push(from);
          },
          error => {
            this.setState({
              email: '',
              password: '',
              isSending: false
            });
          }
      );
  }

  render() {
    return (
      <div className={styles.LoginPage}>
        <div className="container">

          <div className="row justify-content-center">

              <div className="col-xl-12 col-lg-12 col-md-9">

                  <div className="card o-hidden border-0 shadow-lg my-10">
                      <div className="card-body p-0">
                          <div className="row">
                              <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                              <div className="col-lg-6">
                                   <div className="p-5">
                                      <div className="text-center">
                                          <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                      </div>
                                      <form className="user" style={{marginTop: '2.6rem'}}>
                                          <div className="form-group">
                                              <input type="email" className="form-control form-control-user"
                                                  id="exampleInputEmail" aria-describedby="emailHelp"
                                                  placeholder="Enter Email Address..." value={this.state.email}
                                                  onChange={this.handleEmailChanged}/>
                                          </div>
                                          <div className="form-group">
                                              <input type="password" className="form-control form-control-user"
                                                  id="exampleInputPassword" placeholder="Password" value={this.state.password}
                                                  onChange={this.handlePasswordChanged}/>
                                          </div>
                                          <div className="form-group">
                                              <div className="custom-control custom-checkbox small">
                                                  <input type="checkbox" ref={this.rememberMeRef} className="custom-control-input" id="customCheck" />
                                                  <label className="custom-control-label" htmlFor="customCheck">Remember
                                                      Me</label>
                                              </div>
                                          </div>
                                          {this.state.isSending
                                          ? <div className="row justify-content-center">
                                                <div className="spinner-grow" style={{width: "2.4rem", height: "2.4rem"}} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                           : <a className="btn btn-primary btn-user btn-block" onClick={this.handleLoginClicked}>
                                                Login
                                            </a>}
                                          <hr />
                                          <GoogleLogin
                                            clientId="906054534465-jk2to15tvc3jnvgidj49fofmqv1nj95u.apps.googleusercontent.com"
                                            buttonText="Login"
                                            render={renderProps => (
                                              <a onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-google btn-user btn-block">
                                                  <i className="fab fa-google fa-fw"></i> Login with Google
                                              </a>
                                            )}
                                            onSuccess={this.onGoogleSuccess}
                                            onFailure={this.onGoogleFailure}
                                            cookiePolicy={'single_host_origin'}
                                          />
                                      </form>
                                      <hr />
                                      <div className="text-center">
                                          <a className="small" href="/forgot-password">Forgot Password?</a>
                                      </div>
                                      <div className="text-center">
                                          <a className="small" href="/register">Create an Account!</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
    );
  }
}

LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;
