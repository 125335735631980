import React from 'react';
import PropTypes from 'prop-types';
import styles from './Dashboard.module.css';
import { history } from '../../_helpers';
import { authenticationService } from '../../_services';
import WebAppNavBar from '../WebAppNavBar/WebAppNavBar';
import WebAppSideNavBar from '../WebAppSideNavBar/WebAppSideNavBar';
import axios from 'axios';
class Dashboard extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          projects: []
      }
      this.handleSampleProjectClicked = this.handleSampleProjectClicked.bind(this);
      this.handleProjectClicked = this.handleProjectClicked.bind(this);
    }

    handleSampleProjectClicked(sampleProjectName) {
        history.push('/dashboard/sample?name=' + sampleProjectName);
        history.go();
    }

    handleProjectClicked(projectName) {
        console.log(projectName)
        history.push('/dashboard/project?name=' + projectName);
        history.go();
    }

    componentDidMount() {
        axios.get('http://15.236.91.33:443/meshData', {
            headers: {
                'Authorization': 'Bearer ' + authenticationService.currentUserValue.auth_token,
                'Authorization_Source': authenticationService.currentUserValue.auth_token_source
            }})
        .then(res => {
            console.log(res);
            this.setState({projects: res.data.projects});
        })
        .catch(err => console.log(err))
    }

    render() {
        var projects = []
        for (let project of this.state.projects) {
            projects.push(
            <div key={project} className="col-xl-3 col-md-6 mb-3 project-card" onClick={() => this.handleProjectClicked(project)}>
                <div className="card shadow h100 py-0">
                    <div className="card-body pb-3">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="row justify-content-center"><i className="fab fa-unity fa-10x"></i></div>
                                <div className="text-m font-weight-bold-webapp text-primary-webapp">{project}</div>
                                <div className="badge badge-red-soft badge-pill text-ref font-weight-bold-webapp mt-1 w-100">uncompleted</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        }

        return(
            <div className={styles.Dashboard}>
                <div id="wrapper">

                    <WebAppSideNavBar/>
                    <div id="content-wrapper" className="d-flex flex-column">

                        <div id="content">

                            <WebAppNavBar/>
                            <div className="container-fluid">

                                <div className="row h-100">
                                        <div className="col-xl-6 col-md-6 mb-4">
                                            <div className="card border-left-primary shadow h100 py-0">
                                                <div className="card-body">
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col mr-2">
                                                            <div className="text-xl font-weight-bold-webapp text-primary-webapp mb-1">Welcome to</div>
                                                            <div className="text-xl h100 mb-0 font-weight-bold-webapp text-dark-blue">BIO-CFD, <span className="text-lg">{authenticationService.currentUserValue.firstName}</span></div>
                                                            <p className="text-s text-black" >Ready to shape your latest design?<br/>Go ahead and launch a new project!</p>
                                                            <a href="/dashboard/project/new" className="d-none d-sm-inline-block btn btn-m btn-primary-webapp shadow-sm"><i
                                                            className="fas fa-rocket fa-sm text-white-50 mr-1"></i> Start new project </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-6 col-md-6 mb-4">
                                            <div className="card border-left-primary shadow h100 py-0">
                                                <div className="card-body">
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col mr-2">
                                                            <div className="text-xl font-weight-bold-webapp text-primary-webapp mb-1">Ask Expert</div>
                                                            <div className="text-xl h100 mb-0 font-weight-bold-webapp text-dark-blue">Advice</div>
                                                            <p className="text-s text-black" >Want personal assistance by one of our experts?<br/>Just get in touch!</p>
                                                            <a className="d-none d-sm-inline-block btn btn-m btn-primary-webapp shadow-sm"><i
                                                            className="fas fa-envelope fa-sm text-white-50 mr-1"></i> Email </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-xl-6 col-md-6">
                                            <h3 className="font-weight-bold-webapp text-xl text-gray-600 mt-4">Sample projects</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-3 col-md-6 mb-3 project-card" onClick={() => this.handleSampleProjectClicked('sample1.stl')}>
                                            <div className="card shadow h100 py-0">
                                                <div className="card-body pb-3">
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col mr-2">
                                                            <img src="./assets/img/Hex1.png" className="w-100 mb-3"/>
                                                            <div className="text-m font-weight-bold-webapp text-primary-webapp">Sample 1</div>
                                                            <div className="badge badge-green-soft badge-pill text-green font-weight-bold-webapp mt-1 w-100">Finished</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.projects.length > 0
                                     && <div>
                                            <div className="row mb-3">
                                                <div className="col-xl-6 col-md-6">
                                                    <h3 className="font-weight-bold-webapp text-xl text-gray-600 mt-4">Latest projects</h3>
                                                </div>
                                            </div>
                                            <div className="row">
                                               {projects} 
                                            </div>
                                        </div>}
                            </div>
                        </div>

                        <footer className="sticky-footer bg-white">
                            <div className="container my-auto">
                                <div className="copyright text-center my-auto">
                                    <span>Copyright &copy; BIO-CFD 2020</span>
                                </div>
                            </div>
                        </footer>

                    </div>

                </div>
            </div>
        );
    }
}  

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
